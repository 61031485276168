<template>
    <div class="brands">
        <b-overlay
            :show="showLoader"
            variant="transperant"
            opacity="1"
            spinner-variant="primary"
        >
            <b-card no-body>
                <b-card-header>
                    <b-card-title>Team</b-card-title>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                        class="btn-icon ml-auto"
                        @click="addModalShow = true"
                    >
                        Add New Member
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </b-card-header>
                <div class="mb-2 mx-1">
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col
                            cols="12"
                            md="8"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        >
                            <label>Show</label>
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="pageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                            />
                            <label>entries</label>
                        </b-col>
                        <!-- Search -->
                        <b-col
                            cols="12"
                            md="4"
                        >
                            <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="filter"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                            />
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col cols="12">
                        <b-table
                            hover
                            responsive
                            class="position-relative"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :items="members"
                            :fields="fields"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection"
                            :filter="filter"
                            show-empty
                            empty-text="لم يتم العثور على سجلات مطابقة"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                        >
                            <template #cell(user)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar
                                            size="32"
                                        />
                                    </template>
                                    <b-link
                                        class="font-weight-bold d-block text-nowrap"
                                    >
                                        {{ data.item.user.full_name }}
                                    </b-link>
                                    <small class="text-muted">{{ data.item.user.email }}</small>
                                </b-media>
                            </template>

                            <template #cell(brand)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar
                                            size="32"
                                            :src="data.item.brand.image"
                                        />
                                    </template>
                                    <b-link
                                        class="font-weight-bold d-block text-nowrap"
                                    >
                                        {{ data.item.brand.name }}
                                    </b-link>
                                    <small class="text-muted">@{{ data.item.brand.signature }}</small>
                                </b-media>
                            </template>

                            <template #cell(permission)="data">
                                <b-badge :variant="data.item.permission == 'owner' ? 'light-success' : data.item.permission == 'admin' ? 'light-primary':'light-secondary'">
                                    {{data.item.permission}}
                                </b-badge>
                            </template>

                            <template #cell(status)="data">
                                <b-badge :variant="data.item.status == 'approved' ? 'light-success' : data.item.status == 'pending' ? 'light-warning':'light-danger'">
                                    {{data.item.status}}
                                </b-badge>
                            </template>

                            <template #cell(created_at)="data">
                                <b-badge variant="primary">
                                    <feather-icon icon="StarIcon" class="mr-25" />
                                    <span>{{ moment(data.item.created_at).format('llll') }}</span>
                                </b-badge>
                            </template>
                            
                            <template #cell(action)="data">
                                <div class="float-right">
                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-danger"
                                        class="btn-icon mr-1"
                                        @click="deleteBrand(data.item.id)"
                                    >
                                        <feather-icon icon="TrashIcon" />
                                    </b-button>
                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-secondary"
                                        class="btn-icon mr-1"
                                        @click="editModal(data.item)"
                                    >
                                        <feather-icon icon="SettingsIcon" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>

                <b-row class="p-2">
                    <b-col
                        cols="12"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            class="mb-0 mt-1 mt-sm-0"
                            pills
                            first-number
                            last-number
                            prev-class="next-item"
                            next-class="prev-item"
                        />
                    </b-col>
                </b-row>
            </b-card>
        </b-overlay>

        <!-- add modal -->
        <b-modal
            id="modal-2"
            ref="modal-add"
            v-model="addModalShow"
            title="Member"
            ok-title="Send"
            cancel-title="Cancel"
            cancel-variant="outline-secondary"
            no-close-on-backdrop
            @hidden="resetAddModal"
            @ok="handleOkAddModal"
        >
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                                label="Email"
                                label-for="name"
                            >
                                <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required|email"
                                >
                                <b-form-input
                                    v-model="member.email"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Email"
                                    id="name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="Full Name"
                                label-for="name"
                            >
                                <validation-provider
                                #default="{ errors }"
                                name="Full Name"
                                rules="required"
                                >
                                <b-form-input
                                    v-model="member.full_name"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Full Name"
                                    id="name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Brand">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Brand"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="member.brand"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="brands"
                                        label="name"
                                        :reduce="item => item.id"
                                    >
                                        <template #option="{ name, signature, image }">
                                            <div
                                                class="d-flex justify-content-start align-items-center"
                                            >
                                                <b-img
                                                    rounded
                                                    :src="image"
                                                    blank-color="#ccc"
                                                    width="40"
                                                    alt="placeholder"
                                                    class="mr-2"
                                                />
                                                <div class="user-page-info">
                                                    <h6 class="mb-0">{{name}}</h6>
                                                    <small class="text-muted">{{signature}}</small>
                                                </div>
                                            </div>
                                        </template>

                                        <template #selected-option="{ name, image }">
                                            <b-avatar size="sm" :src="image" />
                                            <span class="ml-25">{{ name }}</span>
                                        </template>
                                    </v-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Permission">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Permission"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="member.permission"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="permissions"
                                        label="value"
                                        :reduce="item => item.key"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>

            <template #modal-footer>
                <b-button
                    variant="outline-secondary"
                    class="mr-1"
                    @click="resetAddModal"
                >
                Cancel
                </b-button>
                <b-button
                    variant="primary"
                    :disabled="loading"
                    @click="handleOkAddModal"
                    class="mr-1"
                >   
                    <template v-if="!loading">
                        Save
                    </template>
                    <template v-else>
                        <b-spinner small />
                    </template>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>


<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email } from '@validations'
import {
    BCard, BCardText, BRow, BCol, BButton, BAvatar, BLink, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm,
    BOverlay, BFormCheckbox, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup,
    BInputGroupAppend, BCardTitle, BCardHeader, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
export default {
    components:{
        ValidationProvider, ValidationObserver,
        BCard, BCardText, BRow, BCol, BButton, ToastificationContent,
        BLink, BAvatar, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm, BOverlay, 
        BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup, BCardTitle, BCardHeader,
        BInputGroupAppend, BFormCheckbox, vSelect, BSpinner
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            addModalShow: false,
            loading: false,
            showLoader: true,
            perPage: 5,
            pageOptions: [5, 15, 20],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            fields: [
                {key: 'user', label: 'Member', sortable: true},
                {key: 'brand', label: 'Brand', sortable: true},
                {key: 'permission', label: 'Permission'},
                {key: 'status', label: 'Status'},
                {key: 'created_at', label: 'Created At'},
                {key: 'action', label: ''},
            ],
            brands: [],
            members: [],
            permissions: [{key: 'admin', value:'Admin'}, {key: 'moderator', value:'Moderator'}],
            member: {
                id: null,
                to_user: null,
                permission: null,
                brand: null,
                email: '',
                full_name: '',
            },
            required, email
        }
    },
    mounted(){
        this.getBrands()
        this.getMembers()
    },
    methods: {
        editModal(member){
            this.member.id = member.id
            this.member.to_user = member.user
            this.member.email = member.user.email
            this.member.full_name = member.user.email
            this.member.brand = member.brand.id
            this.member.permission = member.permission
            this.addModalShow = true
        },
        handleOkAddModal(bvModalEvt){
            this.loading = true
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.submitForm()
        },
        async submitForm(){
            await this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    var action, endpoint;
                    if (this.member.id != null){
                        endpoint = `/members/${this.member.id}/`
                        action = 'patch'
                    } else {
                        endpoint = '/members/'
                        action = 'post'
                    }
                    axios[action](endpoint, this.member)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Member Saved',
                                icon: 'CheckIcon',
                                text: 'Member was saved successfully.',
                                variant: 'success',
                            },
                        })
                        this.loading = false
                        this.addModalShow = false
                        this.resetAddModal()
                        this.getMembers()
                        console.log(response);
                    })
                    .catch((error) => {
                        this.loading = false
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Warning !',
                                icon: 'AlertCircleIcon',
                                text: 'Something Went Wrong.',
                                variant: 'danger',
                            },
                        })
                        JSON.stringify(error);
                    })
                } else {
                    setTimeout(() => this.loading=false, 500);
                }
            })
        },
        resetAddModal(){
            this.member.to_user = ''
            this.member.brand = null
            this.member.permission = null
            this.member.full_name = ''
            this.member.email = ''
            this.addModalShow = false
        },
        deleteBrand(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.delete(`/members/${id}/`)
                    .then(response => {
                        console.log(response);
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            text: 'Your Brand has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.getMembers()
                    })
                    .catch(error => {
                        console.log(error);
                    })
                }
            })
        },
        async getBrands(){
            await axios.get("/brands?is_team=1")
            .then(response => {
                this.brands = response.data
                setTimeout(() => { this.showLoader = false }, 500);
            })
            .catch(error => {
                console.log(error);
            })
        },
        getMembers(){
            axios.get("/members")
            .then(response => {
                console.log(response);
                this.members = response.data
                this.totalRows = response.data.length
            })
            .catch(error => {
                console.log(error);
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>
<style lang="scss">
    @import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style>
  .popular { border: 1px dashed var(--primary); }
</style>